<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="4">
        <v-card
          class="pa-4"
          style="min-height: 80vh">
          <input
            accept=".csv"
            type="file"
            @change="loadCSV($event)" />
          <v-form
            ref="form"
            v-model="valid">
            <div
              v-if="csvData.length"
              class="mt-4">
              <v-list-item
                v-for="(header, index) of Object.keys(barcodeHeader)"
                :key="index"
                style="text-transform: uppercase;">
                <v-list-item-content class="d-flex">
                  <v-select
                    v-model="barcodeHeader[header]"
                    :rules="rules.required"
                    :label="header"
                    dense
                    outlined
                    :items="Object.keys(csvData[0])">
                  </v-select>
                </v-list-item-content>
              </v-list-item>
              <v-btn
                width="100%"
                class="mt-4"
                @click="printBarcode()">
                PRINT BARCODE
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card
          class="pa-4"
          style="height: 80vh; overflow: auto;">
          <v-data-table
            :headers="tableHeaders"
            :items="csvData"
            :items-per-page="-1"
            hide-default-footer>
            <template v-slot:[`item.amount`]="{ item }">
              <v-text-field
                v-model.number="item.amount"
                label="Amount"
                type="number"
                min="1"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      csvData: [],
      valid: false,
      rules: {
        required: [
          (v) => !!v || 'This field is required'
        ]
      },
      barcodeHeader: {
        barcode: null,
        text1: null,
        text2: null
      }
    }
  },
  computed: {
    tableHeaders () {
      if (!this.csvData.length) return []
      const results = Object.keys(this.csvData[0]).map((h) => ({
        text: h,
        value: h,
        align: 'center',
        sortable: false
      }))

      return results
    }
  },
  methods: {
    printBarcode () {
      this.$refs.form.validate()
      if (!this.valid) return

      const items = this.csvData.map((d) => ({
        barcode: d[this.barcodeHeader.barcode],
        text1: d[this.barcodeHeader.text1],
        text2: d[this.barcodeHeader.text2],
        amount: d.amount
      }))

      window.localStorage.setItem('print_barcode', JSON.stringify(items))

      // eslint-disable-next-line no-alert
      alert('กรุณาเลือกขนาดกระดาษเป็น 10x6\nรูปแบบ: แนวตั้ง\nระยะขอบกระดาษ: ปิด\nติ๊กส่วนหัวและส่วนท้ายของกระดาษออก')
      const routeData = this.$router.resolve({ name: 'BarcodeGodex' })
      window.open(routeData.href, '_blank')
    },
    loadCSV (e) {
      if (window.FileReader) {
        const reader = new FileReader()
        reader.readAsText(e.target.files[0])
        reader.onload = (event) => {
          const csv = event.target.result
          this.csvJSON(csv)
        }
        reader.onerror = (evt) => {
          if (evt.target.error.name === 'NotReadableError') {
            this.$store.dispatch('Components/setSnackbar', {
              value: true,
              message: 'Cannot read file !',
              type: 'error'
            })
          }
        }
      } else {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: 'FileReader are not supported in this browser.',
          type: 'error'
        })
      }
    },
    csvJSON (csv) {
      this.csvData = []
      const lines = csv.split('\n')
      const headers = lines.shift().split(',')
      for (const line of lines) {
        const temp = line.split(',')
        const data = {}
        data.amount = 0
        for (const [ind, head] of headers.entries()) {
          data[head] = temp[ind].replace(/\r/g, '').trim()
        }
        this.csvData.push(data)
      }
    }
  }
}
</script>
